<div class="row mb-4 yellow titleBar">
    <div class="col-md-3 text-start"><a (click)="navigate()"><i
                class="fa fa-arrow-left"></i>&nbsp;&nbsp;{{'HOME.BACKBUTTON' | translate}}</a></div>
    <div class="col-md-9 text-end">{{'HOME.YELLOWQUES' | translate}}</div>

</div>
<div class="row ">
    <div class="col-md-6 mx-2" *ngFor="let items of categories"
        (click)="setFormFields(items); $event.stopPropagation();">
        <div class="row mb-2  actionButton action" data-allow-start="false" data-a="yellow-5">
            <div class="col my-auto text-start">
                <div class="row">
                    <div>
                        <span class="button-text-small"
                            *ngIf="items.languageKey == null || items.languageKey == ''">{{items.text}}</span>
                        <span class="button-text-small" *ngIf="items.languageKey != null && items.languageKey != ''">{{
                            "HOME."+items.languageKey | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #yellowpopup let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ "HOME.SET_REMINDER" | translate }}
        </h2>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="statusForm" novalidate class="d-flex flex-column m-auto">
            <div class="form-group row " style="display: flex;">
                <label class="col-sm-11 col-form-label">{{ "HOME.SET_REMINDER_DESCP" | translate }}</label>
            </div>
            <div class="col-sm-8">
                <div class="col-xs-12 col-12 col-sm-5 col-md-6 col-lg-4 form-group mb-3">
                </div>
                <!-- min="minDate" -->
                <mat-form-field>
                    <input matInput [matDatepicker]="dp" [min]="minDate" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="dp" id="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="SetTime()">
            {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn btn-outline-dark"
            (click)="modal.close('Save click')">
            {{ "HOME.CANCELBUTTON" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #popupformmodal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="popupform" (keyup.enter)="submitdropdown()" novalidate>
            <div *ngFor="let a of placeholder;let i=index">
                <div class="mb-3">
                    <div class="form-group">
                        <input type="text" class="form-control" [formControlName]="a" placeholder="{{a}}">
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="dropdown_values.length > 0">
                <select class="form-select form-select-lg" formControlName="mail" [(ngModel)]="dropdown_values[0]"
                    aria-label=".form-select-sm example">
                    <option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitdropdown()">{{'HOME.CONFIRMBUTTON' |
            translate}}</button>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn btn-success reset"
            (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
    </div>
</ng-template>