import { Customers, Products } from './customerlist';

export interface ERPCustomers {
  response: string,
  result: Customers[]
}

export interface ERPProducts {
  response: string,
  result: Products[]
}

export interface Createorder{
  order:string,
response:string
}