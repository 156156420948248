import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {base_routes} from 'src/app/constant';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) {
  }

  canActivate(): boolean {
    const isAuthenticated = localStorage.getItem('smartbox_Authenticated');
    const role = localStorage.getItem('role');
    if (isAuthenticated == 'true' && role == 'office') {
      return true;
    }
    this.router.navigate([base_routes.LOGIN]);
    return false;
  }
}
