import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxUiLoaderService, NgxUiLoaderConfig } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/services/apiservice.service';
import { Router } from '@angular/router';
import { base_routes } from 'src/app/constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  closeResult: string;
  registrationForm: any;
  submitted: boolean=false;
  password: string;
  show: boolean=false;

  constructor( private modalService: NgbModal,private ngxService: NgxUiLoaderService, private spinner: NgxSpinnerService, private toastrService: ToastrService, private router: Router, public fb: UntypedFormBuilder, private apiservice: ApiService) {
  
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required]],
      passwords: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  ngOnInit(): void {
    this.password = 'password';
  }

  get f() { return this.registrationForm.controls; }


  onSubmit() {
    if (this.registrationForm.valid) {
      this.ngxService.start();
      let data = {
        "email":this.registrationForm.value.email,
        "password":this.registrationForm.value.passwords
      }
      this.apiservice.doLogin(data).subscribe(
        (res: any) => {
          this.ngxService.stop();
          this.registrationForm.reset({});
          if (res['login'] == true) {
            this.modalService.dismissAll();
            this.toastrService.success('Logged-in!', 'Success!');
            sessionStorage.setItem("smartbox_token", res.cookie);
            localStorage.setItem("workstationID", res.workstationID);
            localStorage.setItem("smartbox_name", res.workstationName);
            localStorage.setItem("customerID",res.customerID);
            localStorage.setItem("smartbox_Authenticated", "true");
            
            if (res.template == '1') {
              localStorage.setItem("role", "office");
              this.router.navigate([base_routes.HOME + '/' + base_routes.LANDING_PAGE]);
            } else {
              localStorage.setItem("role", "shopfloor");
              this.router.navigate([base_routes.USER + '/' + base_routes.DASHBOARD]);
            }
          } else {
            this.toastrService.error('Login failed!', 'Error!');
          }
        }, (error) => {
        })
    } else {
      this.submitted = true;
      return;
    }
  }
 
  navigate(){
this.modalService.dismissAll();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([base_routes.FORGOTPASSWORD]); // navigate to same route
    });
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  open(content: any) {
        
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }




private getDismissReason(reason: any): string {
if (reason === ModalDismissReasons.ESC) {
  return 'by pressing ESC';
} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  return 'by clicking on a backdrop';
} else {
  return `with: ${reason}`;
}
}
}
