import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptor implements HttpInterceptor {
  cookie: any;

  constructor() {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.cookie = localStorage.getItem("smartbox_token");
    request = request.clone({
      headers: request.headers.set('Authorization', `appAuthName=${this.cookie}`),
    });
    return next.handle(request);
  }

}
