
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from 'src/services/apiservice.service';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpErrorInterceptor} from '../services/http-interceptor';
import {TokenInterceptor} from '../services/token-interceptor.service'
import {AuthGuardService} from 'src/services/auth-guard.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DataService} from 'src/services/dataservice.service';
import {RoleGuardService} from 'src/services/role-guard.service';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {ToastrService} from 'ngx-toastr';
import {deLocale, frLocale, plLocale} from 'ngx-bootstrap/locale';
import {defineLocale} from 'ngx-bootstrap/chronos';
import { LoginAuthGuard } from 'src/services/login-guard.service';

defineLocale('de', deLocale);
defineLocale('fr', frLocale);
defineLocale('pl', plLocale);
import {BackButtonDisableModule} from 'angular-disable-browser-back-button';
import {ChecklistModalComponent} from '../basemodule/checklist-modal/checklist-modal.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'du',
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000, // 3 seconds
      progressBar: true,
      preventDuplicates: true
    }),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    //  CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [ChecklistModalComponent,LoginAuthGuard, ApiService, AuthGuardService, DataService, RoleGuardService, ToastrService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      //deps: [Router, ActivatedRoute,ToastrModule],
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
