import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import { LoggedInUser } from 'src/models/loggedinuser';
import {ApiService} from 'src/services/apiservice.service';
import { base_routes } from './constant';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smartbox-fe';
  public screenWidth: any;  
  public screenHeight: any; 
  fixedHeight:any;
  reduceHeight:boolean | undefined;
  
  constructor(public location:Location,public apiService:ApiService,private translateService: TranslateService,private router:Router,private activatedRoute:ActivatedRoute) {
    this.screenHeight = window.innerHeight;
    if(!window.location.href.includes('login'))
    {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      var token : any = params.get("autologin");
      console.log("inside if",params.get("autologin"));
      if(token)
      {   
     let payload = { token };
            this.apiService.validateAutoLoginToken(payload).subscribe(
              (res: LoggedInUser) => {
                if (res.login === true) {
                  localStorage.setItem("smartbox_token", res.cookie);
                  localStorage.setItem("workstationID", res.workstationID);
                  localStorage.setItem("smartbox_name", res.workstationName);
                  localStorage.setItem("customerID", res.customerID);
                  localStorage.setItem("smartbox_Authenticated", "true");
                  if (res.template == '1') {
                    localStorage.setItem("role", "office");
                    this.router.navigate([base_routes.HOME + '/' + base_routes.LANDING_PAGE] ,{ queryParams: {}});
                  } else {
                    localStorage.setItem("role", "shopfloor");
                    this.router.navigate([base_routes.USER + '/' + base_routes.DASHBOARD], { queryParams: {}});
                  }
                } 
                this.location.replaceState(this.location.path().split('?')[0], '');
                window.location.reload();
              }, (error) => {
                console.log(error)
              })
    }
    });
  }
  this.fixedHeight=window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
   onResize(event:any) {
    this.reduceHeight=false
     this.screenWidth = event.target.innerWidth;  
     this.screenHeight = event.target.innerHeight;
     if(this.fixedHeight<this.screenHeight){
       this.reduceHeight=true;
      //  console.log(this.fixedHeight,'fixedHeight')
      //  console.log(this.screenHeight)
     }
     
   }

  public changeLanguage(language: string): void {
    this.translateService.use(language);
  }
}
