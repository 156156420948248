import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { base_routes } from 'src/app/constant';


@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public router: Router,) {

  }

  canActivate(): boolean {

    const role = localStorage.getItem('role');
    const isAuthenticated = localStorage.getItem('smartbox_Authenticated');
    if (role == "shopfloor" && isAuthenticated == 'true') {
      return true;
    }
    this.router.navigate([base_routes.LOGIN]);
    return false;
  }
}
