import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class DataService {
  myMethod$: Observable<any>;
  subject = new Subject();
  private myMethodSubject = new Subject<any>();
  data: any = [];
  observable: Observable<any>;
  private newUser = new BehaviorSubject<any>(null);

  private user = new BehaviorSubject<any>({
    flag: false
  });

  language: EventEmitter<any> = new EventEmitter<any>();

  updateData(data: any) {
    console.log(data,'data in service')
    this.language.emit(data);
  }

  setNewUserInfo(user: any) {
    this.user.next(user);
  }

  getNewUserInfo() {
    return this.user.asObservable();
  }

  private messageSource = new Subject<string>();
  currentMessage = this.messageSource.asObservable();


  private projectModal = new Subject<string>();
  projectmodalState = this.projectModal.asObservable();

  private booleanValue = new BehaviorSubject(false);
  castValue = this.booleanValue.asObservable();


  private modalBooleanValue = new Subject<string>();
  modalValue = this.modalBooleanValue.asObservable();

  constructor() {
    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  myMethod() {
    const studentDetail = new Observable((observer) => {
      observer.next(this.data);
    });
    return studentDetail;
  }

  sendValue(newValue: boolean) {
    this.booleanValue.next(newValue);
  }

  sendModalValue(newValue: string) {
    this.modalBooleanValue.next(newValue);
  }

  setProjectModal(Value: string) {
    this.projectModal.next(Value);
  }

  set(data: any) {
    this.newUser.next(data);
  }

  get() {
    return this.newUser.asObservable();
  }

  async add(message: any) {
    this.data = message;
  }

  async getOrder() {
    return this.data;
  }

  async resetOrder() {
    this.data = undefined;
  }
}
