<footer class="footer bg-light mt-auto float-center">
  <div class="row footer-row">
    <div class="col text-muted align-self-center text-cc">
      © smart.box {{ year }}. All Rights Reserved.
    </div>
    <div class="col content-footer-logo align-self-center text-center">
      <img
        src="https://smartbox-solutions.nl/wp-content/uploads/2021/03/logo_1200x300-300x75.png"
        width="150"
      />
    </div>
    <div class="col text-muted justify-content-end align-self-center text-center" style="cursor: pointer;" (click)="reload()" (touchend)="reload()">
      Version: {{ data.VERSION }}
    </div>
  </div>
</footer>