
import { ordersResponse } from '../models/orders'

export interface Order {
  errorMessage: string;
  response: string,
  eventID: number,
  orderID: string,
  eventDescription: string,
  openOrders: ordersResponse,
  ERPOrders: number,
  print: string,
  triggerChecklist: any,
  triggerPrint: any
}

export interface ActiveOrder {
  eventID?: string,
  customerID?: string,
  userID?: string,
  eventType?: string,
  eventDetail: string,
  orderID: string,
  startTime: string,
  endTime: string | null,
  monitorStartTime: string,
  monitorEndTime: string | null,
  datapointIDs: string | null,
  customerRef: string | null,
  reminderDate: string | null,
  fkWorkstationID: string | null,
  eventDescription: string | null,
  languageKey: string | null,
  focus: boolean
}
export interface orderevendetails {
  bgColor: null,
  extraLevel: null,
  hidden: null,
  languageKey: null,
  prefix: null,
  preventSkipExtraLevel: string,
  text: string,
  value: string
}

export interface currentOrder {

  eventID: string,
  customerID: string,
  userID: string,
  eventType: string,
  eventDetail: string,
  orderID: string,
  startTime: string,
  endTime: string | null,
  monitorStartTime: string,
  monitorEndTime: string | null,
  datapointIDs: string | null,
  customerRef: string,
  reminderDate: string,
  fkWorkstationID: string,
  eventDescription: string | null,
  languageKey: string | null,
  focus: boolean,

}


export interface Openorders {
  allowExecute: string,
  batch_on_orderline: string,
  mapping_field: string,
  orderID: string | undefined,
  customerID: string,
  customerName: string,
  numberOfPallets: string,
  dueDate: string,
  readyDate: string,
  customerReference: string,
  sortfield: string,
  orderlines: Orderlines[],
  isExist: boolean,
  username: string,

}
interface Orderlines {
  lineID: string,
  productID: string,
  quantity: string,
  pallets: string,
  batch_codes: string

}

export interface Cuslist {
  Country: string,
  CustomerID: string,
  Klantnaam: string,
  Plaats: string,
  Streetname: string,
  Zipcode: string,
  batch_on_orderline: string,
  batch_prefix: string
}

