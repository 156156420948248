import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of, interval } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { base_routes } from 'src/app/constant';
import { ApiService } from './apiservice.service';
@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  pendingRequestsCount = 0;
  workstationid: any;
  skiperrorLog='';
  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.workstationid = localStorage.getItem('workstationID');
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.pendingRequestsCount=0;
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
      
        let errorMessage = '';
      
        if (error.url == 'http://localhost:8000') {
          return throwError(errorMessage);
        } else {
         if (error.status != 401) {
            let obj = {
              workstationID: +this.workstationid,
              trigger: request.url + ' ,method:' + request.method,
              data :error.error.text?error.error.text:JSON.stringify(error)
            };

            if (this.pendingRequestsCount == 0) {
                this.skiperrorLog=obj.data
              this.apiService.errorLog(obj);
            } else {
              const subscription = interval(5000).subscribe((val) => {
                if(this.skiperrorLog != obj.data){
                  this.apiService.errorLog(obj);
                }
              });
              setTimeout(() => {
                subscription.unsubscribe();
              }, this.pendingRequestsCount * 5000);
            }
          }
          if (error.status === 401) {
            this.toastrService.error('Unauthorized request', 'Login again');
          } else if (error.status === 500) {
            this.toastrService.error('Internal server error', 'Login again');
          } else {
            this.toastrService.error('Something went wrong', 'Login again');
          }
          let lastlogin: any = localStorage.getItem('LastLogin');
          localStorage.clear();
          localStorage.setItem('LastLogin', lastlogin);
         this.router.navigate([base_routes.LOGIN]);
         this.pendingRequestsCount++;
          return throwError(errorMessage);
        }
      })
    );
  }
}
