export interface customizing {
    response: responseCustomizing
}

interface Checklist {
    [index: number]: string;
}

interface triggerPrint {
    processStep: string
    askQuantity: boolean
    autoprint: boolean
}

export interface responseCustomizing {
    allow_ImprovementIdea: boolean;
    orderTag: string;
    numberOfGreenActivities: string;
    allow_attachments: boolean;
    ordernumber_mask: string | null;
    ordernumber_mask_description: string | null;
    refresh_timeout: string;
    showAppTimer: string;
    setTimerForOrange: string;
    showChecklist: string[];
    showInstructions: string | null;
    triggerPrint: triggerPrint[];
    allow_createERPOrders: string;
    allow_editERPOrders: string;
    allow_deleteERPOrders: string;
    allow_StockManagement: string;
    autoStartGreenAfterOrderCreation: string;
    showSearchInERPOrderModal: string;
    startEventAfterOrderCreation: string | null;
}
