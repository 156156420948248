import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import  version_config from '../../src/assets/version.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent  {
  loginPage: boolean=false;
  data: any;
  year: number;

  
  constructor(private router: Router) {  
    this.year = new Date().getFullYear();
     this.getRoute();
     this.data = version_config;
  }

  getRoute(){
    this.router.events.subscribe((routerEvent) => {
      if(routerEvent instanceof NavigationEnd) {
          (routerEvent.url == '/login')?this.loginPage = false:this.loginPage = true;
      }
  });
  }

  reload(){
    window.location.reload();
  }
}
