export interface Language{
    name:string,
    value:string
}
export interface Productdetails{
    ID: string,
    Name: string,
    Descp: string,
}
export interface AvailableUsers {
    null: any,
    active: string,
    displayName: string,
    emailAddress: string,
    enable2FA: string,
    firstName: string;
    fkCustomerID: string;
    fkWorkstationID: string;
    language: string;
    lastName: string;
    normHours: any;
    notificationMedium: any;
    password: any;
    phoneNumber: any;
    role: any;
    timeScheme: string;
    userID: string;
    userType: string;
  }
  
export interface currentScreen{

        screen_status:boolean
        downtime_flag:boolean
        workinghours_flag:boolean
        unplanned_flag:boolean
        reports_flag:boolean
        project_flag:boolean
}