import {Injectable} from '@angular/core';
import {Router, CanActivate,ActivatedRoute } from '@angular/router';
import {base_routes} from 'src/app/constant';
import { Location } from '@angular/common';
import { LoggedInUser } from 'src/models/loggedinuser';
import {ApiService} from 'src/services/apiservice.service';
@Injectable()
export class LoginAuthGuard implements CanActivate {
  token: string | null;
  constructor(public location:Location,public apiService:ApiService,public router: Router,public activatedRoute:ActivatedRoute) {
  }

  canActivate() : boolean {
  if(window.location.href.includes('autologin'))
  {
   localStorage.removeItem('smartbox_token');
   localStorage.removeItem('role');
  }
  const token = localStorage.getItem('smartbox_token');
  const role = localStorage.getItem("role");
    
    if (!token ) {
      return true;
    }

      (role === 'office')?
      this.router.navigate([base_routes.HOME + '/' + base_routes.LANDING_PAGE]): this.router.navigate([base_routes.USER + '/' + base_routes.DASHBOARD]);
      return false;   
}
}