<!-- <nav class="navbar">
    <div class="container">
        <a class="navbar-brand" href=""><img
                src="https://smartbox-solutions.nl/wp-content/uploads/2021/03/logo_1200x300-300x75.png"></a>
        <div>
            <span class="mr-3" id="#"></span>
            <span class="mr-3" id="userName"></span>
            <button type="button" class="btn btn-primary" id="menuLoginButton" (click)="open(content)">Login</button>
        </div>
    </div>
</nav> -->




<ng-template #content let-modal >
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Login</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="registrationForm" novalidate>
            <div class="input-group mb-3">
                <input type="text" formControlName="email" [ngClass]="{'is-invalid': submitted && f.email.errors }" required  class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                <span class="input-group-text" id="basic-addon1">  <i class="fa fa-user icon"></i></span>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
              
              </div>
              <div class="input-group mb-3">
                <input [type]="password" [ngClass]="{'is-invalid': submitted && f.passwords.errors }" formControlName="passwords" required class="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1">
                <span class="input-group-text" id="basic-addon1" (click)="onClick()">   <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i></span>
                    <div *ngIf="submitted && f.passwords.errors" class="invalid-feedback">
                        <div *ngIf="f.passwords.errors.required">Password is required</div>
                    </div>
              
              </div>
        </form>
        <a  class="link-primary" (click)="navigate()">Forgot password?</a>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-primary" (click)="onSubmit()">Login</button>
    </div>
</ng-template>

