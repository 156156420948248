import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { base_routes } from '../app/constant';
import { AuthGuardService as AuthGuard } from 'src/services/auth-guard.service';
import { RoleGuardService as RoleGuard } from 'src/services/role-guard.service';
import { LoginAuthGuard } from 'src/services/login-guard.service';
const routes: Routes = [
  { path: '', redirectTo: base_routes.LOGIN, pathMatch: 'full' },
  {
    path: base_routes.LOGIN,
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: base_routes.FORGOTPASSWORD,
    loadChildren: () => import('../forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: base_routes.HOME,
    loadChildren: () => import('../basemodule/basemodule.module').then(m => m.BasemoduleModule),
    canActivate: [AuthGuard],
    data: { 
      role: 'office'
    } 
  },{
    path: base_routes.USER,
    loadChildren: () => import('../usermodule/usermodule.module').then(m => m.UsermoduleModule),
    canActivate: [RoleGuard],
    data: { 
      role: 'shopfloor'
    } 
  }
  // ,
  // {
  //   path: '**',
  //   redirectTo: base_routes.LOGIN
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true, enableTracing: false
  },)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
